import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import getDataProps from 'commons/getDataProps';

import CtaWrapper from './CtaWrapper';
import CtaContent from './CtaContent';

const Cta = React.forwardRef(
  (
    {
      'aria-describedby': ariaDescribedBy,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      as,
      block = false,
      children,
      className = '',
      color = 'black',
      'data-xc': dataXc,
      disabled = false,
      download,
      hasArrow = true,
      noHorizontalPadding = false,
      hasBackArrow = false,
      hasUnderline = false,
      id,
      isBold = true,
      isHovered,
      isLoading = false,
      itemProp = null,
      itemType = null,
      noLinkPadding = false,
      onClick,
      onMouseDown,
      onMouseLeave,
      onMouseUp,
      onTouchEnd,
      onTouchStart,
      size = 'base',
      spacing = 'md',
      style = {},
      styling = 'link',
      tabIndex,
      target,
      textClassName,
      to,
      type,
      ...otherProps
    },
    ref
  ) => (
    <CtaWrapper
      aria-describedby={ariaDescribedBy}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      as={as}
      className={classNames(
        `group/cta font-sans relative [&_svg]:!mt-0 [&_svg]:!mr-2 [&_svg]:!mb-0 [&_svg]:!ml-0 hover:cursor-pointer no-underline select-none ${className}`,
        {
          // Padding and spacing
          'p-0':
            styling === 'simple-img-button' ||
            (styling === 'link' && noLinkPadding) ||
            (styling === 'link' && !hasArrow && !hasBackArrow),
          'inline-block':
            ['simple-img-button', 'solid-button'].includes(styling) && !block,
          'inline-flex':
            ['outline-button', 'rainbow-button'].includes(styling) && !block,
          'w-full flex justify-center items-center': block,
          'min-w-[140px]':
            [
              'outline-button',
              'rainbow-button',
              'simple-img-button',
              'solid-button',
            ].includes(styling) && ['sm', 'md'].includes(spacing),
          'py-2 px-4':
            ['outline-button', 'solid-button'].includes(styling) &&
            spacing === 'xs',
          'py-2 px-8':
            ['outline-button', 'solid-button'].includes(styling) &&
            spacing === 'sm',
          'py-[0.8rem] px-8':
            ['outline-button', 'solid-button'].includes(styling) &&
            spacing === 'md',
          // simple-img-button
          'border-0 duration-200 ease-out w-full':
            styling === 'simple-img-button',
          // solid-button
          'border-2 border-solid border-transparent rounded-sm transition-[background,border-color,color] duration-200 ease-out focus:shadow-[0_0_0_4px_#fff,0_0_0_5px_#717171,0_0_0_6px_hsla(0,0%,100%,.5)] focus:outline-0 focus:[transition:box-shadow_.2s_ease_0s] disabled:bg-gray-light6 disabled:text-gray disabled:cursor-not-allowed':
            styling === 'solid-button',
          'bg-gray-dark text-white active:bg-black focus:bg-black hover:bg-black':
            styling === 'solid-button' && color === 'black',
          'bg-brand text-white active:bg-[#0d6370] focus:bg-[#0d6370] hover:bg-[#0d6370]':
            styling === 'solid-button' && color === 'brand',
          'bg-brand-lightest text-brand-dark [transition:background-color_.15s_ease] active:bg-[#afd4da] hover:bg-brand-lighter':
            styling === 'solid-button' && color === 'brand-light',
          'bg-[#de1c85] text-white active:bg-[#b2166a] focus:bg-[#b2166a] hover:bg-[#b2166a]':
            styling === 'solid-button' && color === 'hotpink',
          'bg-white text-gray-dark active:bg-white hover:bg-white':
            styling === 'solid-button' && color === 'white',
          // outline-button
          'border-2 border-solid rounded-sm items-center bg-transparent active:bg-transparent hover:bg-transparent focus:shadow-[0_0_0_4px_#fff,0_0_0_5px_#717171,0_0_0_6px_hsla(0,0%,100%,.5)] focus:outline-0 focus:[transition:box-shadow_.2s_ease_0s] disabled:cursor-not-allowed':
            styling === 'outline-button',
          'border-2 border-solid border-gray text-gray-dark focus:border-brand focus:text-brand hover:border-brand hover:text-brand':
            styling === 'outline-button' && color === 'black',
          'border-2 border-solid border-brand text-brand focus:border-brand-darker focus:text-brand-darker hover:border-brand-darker hover:text-brand-darker':
            styling === 'outline-button' && color === 'brand',
          'border-2 border-solid border-white text-white focus:border-white focus:text-white focus:[outline-style:auto] focus:outline-1 focus:outline-white hover:border-white hover:text-white hover:[outline-style:auto] hover:outline-1 hover:outline-white':
            styling === 'outline-button' && color === 'white',
          // rainbow-button
          'pt-[10px] px-[22px] pb-[9px]': styling === 'rainbow-button',
          'border-0 bg-white [transition:all_.2s_linear] items-center justify-center relative text-gray-dark text-center rounded-[100px]':
            styling === 'rainbow-button',
          "before:content-'' before:block before:absolute before:rounded-[inherit] before:[transition:all_.2s_linear] before:z-0 before:w-full before:h-full before:bg-rainbow":
            styling === 'rainbow-button',
          "after:content-'' after:block after:absolute after:rounded-[inherit] after:[transition:all_.2s_linear] after:bg-white after:h-[calc(100%_-_4px)] after:w-[calc(100%_-_4px)] after:z-[1]":
            styling === 'rainbow-button',
          'focus:outline-0 before:focus:shadow-[0_0_0_3px_rgba(131,192,253,.5)] before:focus:outline-0 after:focus-visible:h-[calc(100%_-_10px)] after:focus-visible:w-[calc(100%_-_10px)] hover:bg-white before:hover:[transform:scale(1.00568,1.02273)] after:hover:[transform:scale(.99419,.975)] active:bg-white':
            styling === 'rainbow-button',
          'bg-white before:[transform:scale(1.00568,1.02273)] after:[transform:scale(.99419,.975)]':
            styling === 'rainbow-button' && isHovered,
          // Link style
          'bg-transparent border-0 relative focus-visible:outline-offset-[3px] active:bg-transparent hover:bg-transparent':
            styling === 'link',
          'text-brand':
            (styling === 'link' && color === 'brand') ||
            (styling === 'link' &&
              isHovered &&
              (hasBackArrow || color === 'black')),
          'text-red': styling === 'link' && color === 'red',
          'text-gray-dark hover:text-brand':
            styling === 'link' && color === 'black',
          'text-white': styling === 'link' && color === 'white',
          'hover:text-brand': styling === 'link' && hasBackArrow,
          'text-brand hover:text-gray-dark':
            styling === 'link' &&
            hasArrow &&
            ['brand', 'brand-light'].includes(color),
          'hover:text-white':
            styling === 'link' && hasArrow && color === 'white',
          // Background colors
          'bg-transparent':
            (styling === 'link' && isHovered) ||
            (styling === 'outline-button' &&
              isHovered &&
              !['black', 'brand', 'white'].includes(color)),
          // Background colors: hover
          'bg-white':
            styling === 'solid-button' && isHovered && color === 'white',
          // Border & text colors: hover
          'border-brand text-brand':
            styling === 'outline-button' && isHovered && color === 'black',
          'border-brand-darker text-brand-darker':
            styling === 'outline-button' && isHovered && color === 'brand',
          'border-white text-white outline-1 [outline-style:auto] outline-white':
            styling === 'outline-button' && isHovered && color === 'white',
          // Text sizes
          'text-xs': size === 'xs',
          'text-sm': size === 'sm',
          'text-base': size === 'base',
          'text-lg': size === 'lg',
          'text-xl': size === 'xl',
          'text-2xl': size === '2xl',
          'text-3xl': size === '3xl',
          'text-4xl': size === '4xl',
          // Text styles
          'font-bold': isBold,
          // hasArrow and hasBackArrow
          'pt-[0.8rem] pr-[2.4rem] pb-[0.8rem] pl-[1.4rem]':
            styling === 'link' && (hasArrow || hasBackArrow),
          '!px-0': noHorizontalPadding,
          // Hover
          'cursor-pointer': isHovered,
        }
      )}
      data-xc={dataXc}
      disabled={disabled}
      download={download}
      id={id}
      itemProp={itemProp}
      itemType={itemType}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
      ref={ref}
      style={style.background}
      tabIndex={tabIndex}
      target={target}
      to={to}
      type={type}
      {...getDataProps(otherProps)}
    >
      <CtaContent
        className={textClassName}
        color={color}
        hasArrow={hasArrow}
        hasBackArrow={hasBackArrow}
        hasUnderline={hasUnderline}
        isHovered={isHovered}
        isLoading={isLoading}
        style={style.text}
        styling={styling}
      >
        {children}
      </CtaContent>
    </CtaWrapper>
  )
);

Cta.propTypes = {
  'aria-describedby': PropTypes.string,
  'aria-label': PropTypes.string,
  'aria-labelledby': PropTypes.string,
  as: PropTypes.string,
  block: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'black',
    'brand-light',
    'brand',
    'hotpink',
    'red',
    'white',
  ]),
  'data-xc': PropTypes.string,
  disabled: PropTypes.bool,
  download: PropTypes.bool,
  hasArrow: PropTypes.bool,
  hasBackArrow: PropTypes.bool,
  hasUnderline: PropTypes.bool,
  id: PropTypes.string,
  isBold: PropTypes.bool,
  isHovered: PropTypes.bool,
  isLoading: PropTypes.bool,
  itemProp: PropTypes.string,
  itemType: PropTypes.string,
  noLinkPadding: PropTypes.bool,
  noHorizontalPadding: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl']),
  spacing: PropTypes.oneOf(['xs', 'sm', 'md']),
  style: PropTypes.object,
  styling: PropTypes.oneOf([
    'link',
    'outline-button',
    'rainbow-button',
    'simple-img-button',
    'solid-button',
  ]),
  tabIndex: PropTypes.string,
  target: PropTypes.string,
  textClassName: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
};

export default Cta;
