import React from 'react';
import { Helmet } from 'react-helmet-async';
import { logCriticalError, logError } from 'commons/logger';
import { JBErrorContentContext } from 'commons/context/JBErrorContentContext';
import PropTypes from 'prop-types';

class ErrorFallbackPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    logCriticalError(error, 'HTTP_500');
    logError(error, { message: 'Error happened:', info });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    const {
      content: { error500 },
    } = this.context;

    return (
      <>
        <Helmet>
          <title>Error | Joybird</title>
        </Helmet>
        {error500}
      </>
    );
  }
}

ErrorFallbackPage.contextType = JBErrorContentContext;

ErrorFallbackPage.propTypes = {
  children: PropTypes.node,
};

export default ErrorFallbackPage;
