import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import isClient from 'commons/isClient';
import { VALIDATE_PRINT_CAMPAIGN } from 'mocks/queries';
import IconCloseEllipse from 'components/icons/iconCloseEllipse';
import JBImage from 'components/essentials/JBImage';
import withApolloClient from 'components/hoc/withApolloClient';

const PrintPromoBanner = props => {
  const [show, setShow] = useState(false);
  const [couponData, setCouponData] = useState(null);

  const bannerClose = () => {
    setTimeout(() => {
      setShow(false);
    }, 200);
  };

  useEffect(() => {
    const validatePromo = async () => {
      const printCampaignValidation = await props.client.query({
        query: VALIDATE_PRINT_CAMPAIGN,
        fetchPolicy: 'network-only',
        variables: {
          landedOn: window.location.href,
        },
      });

      if (printCampaignValidation?.data?.validatePrintCampaign?.valid) {
        setTimeout(() => {
          setShow(true);
        }, 250);
        setCouponData({
          ...printCampaignValidation?.data?.validatePrintCampaign,
        });
      }
    };

    const PRINT_PROMO_BANNER_ENABLED =
      process.env.NEXT_PUBLIC_PRINT_PROMO_BANNER_ENABLED ??
      window._env_.REACT_APP_PRINT_PROMO_BANNER_ENABLED ??
      '';
    if (
      window.location.search !== '' &&
      isClient() &&
      PRINT_PROMO_BANNER_ENABLED === 'yes'
    ) {
      validatePromo();
    }
  }, [props.client]);

  if (!couponData) {
    return null;
  }

  return (
    <div
      className={classNames(
        'relative z-[10001] [background:url(https://joybird2.imgix.net/print-promo-bg-01.png?auto=compress%2Cformat)] bg-cover flex justify-between items-center overflow-hidden [transition:all_1s_ease-in-out]',
        {
          'h-0': !show,
          'h-[100px]': show,
        }
      )}
    >
      <div className="min-w-[10px] h-full flex-[2] flex flex-col justify-end" />
      <div className="flex-grow flex self-end max-[450px]:hidden">
        <div className="left-8 relative [&_img]:block [&_img]:h-[58px]">
          <JBImage
            src="https://joybird2.imgix.net/print-promo-ellipse-03.png"
            alt="circle-01"
            height={58}
          />
        </div>
        <div className="[&_img]:block [&_img]:h-[72px]">
          <JBImage
            src="https://joybird2.imgix.net/print-promo-ellipse-02.png"
            alt="circle-02"
            height={72}
          />
        </div>
      </div>
      <div className="flex-grow flex flex-col items-center">
        <div className="font-sans non-italic font-normal text-[24px] leading-[28px] tracking-[.2px] text-gray-dark max-[450px]:text-[12px] max-[450px]:leading-[16px]">
          Podcast listeners save
        </div>
        <div className="font-sans non-italic font-bold text-[28px] leading-[32px] tracking-[.2px] text-gray-dark max-[450px]:text-[24px]">
          {couponData?.couponType !== 'Standard' &&
            couponData?.couponType !== '' &&
            '$'}
          {couponData?.couponDiscount || '0'}
          {(couponData?.couponType === 'Standard' ||
            couponData?.couponType === '') &&
            '%'}{' '}
          off
        </div>
      </div>
      <div className="flex-grow flex flex-col items-center">
        <div>
          <div className="bg-white w-fit p-2.5 rounded-[5px] font-sans non-italic font-normal text-[19px] leading-[28px] tracking-[.2px] text-gray max-[450px]:text-[16px] max-[450px]:leading-[16px]">
            {couponData?.couponCode || 'SAVE25'}
          </div>
          <div />
        </div>
        <div className="mt-2.5 font-sans non-italic font-normal text-[11.52px] leading-[15px] tracking-[.3px] text-gray-dark max-[450px]:text-[10px] max-[450px]:leading-[12px] max-[450px]:text-center [&_br]:hidden [&_br]:max-[450px]:inline">
          *use code at checkout <br />
          {couponData?.expiration === 'never'
            ? ''
            : `before ${couponData?.expiration}`}
        </div>
      </div>
      <div className="flex min-w-[80px] flex-[2] justify-end cursor-pointer max-[450px]:min-w-[30px] max-[450px]:flex-grow">
        <button
          type="button"
          className="text-gray-dark rounded-[20px] w-5 text-center [background:none] border-0 cursor-pointer mr-10 max-[450px]:mr-2"
          onClick={bannerClose}
        >
          <IconCloseEllipse />
        </button>
      </div>
    </div>
  );
};

PrintPromoBanner.propTypes = {
  client: PropTypes.shape({
    query: PropTypes.func,
  }),
};

export default withApolloClient(PrintPromoBanner);
