/**
 * DM24 - 404 Component.
 *
 * When someone visits a route that does not exist, display this component.
 */

import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

import isClient from 'commons/isClient';
import { logCriticalError } from 'commons/logger';
import { JBErrorContentContext } from 'commons/context/JBErrorContentContext';
import { withRouter } from 'react-router-dom';

const Component404 = ({ staticContext, statusCode }) => {
  const { content } = useContext(JBErrorContentContext);

  useEffect(() => {
    logCriticalError(
      new Error(`404 error for ${document.location.href}`),
      'HTTP_404'
    );
  }, []);

  if (!isClient()) {
    staticContext.status = statusCode || 404;
  }

  return (
    <>
      <Helmet>
        <title>Page Not Found | Joybird</title>
      </Helmet>
      <section>{content.error404}</section>
    </>
  );
};

Component404.propTypes = {
  staticContext: PropTypes.shape({
    status: PropTypes.number,
  }),
  statusCode: PropTypes.number,
};

export default withRouter(Component404);
